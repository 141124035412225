header {
  background: #fff;
  padding: 20px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
  position: relative;
  z-index: 44;
}
.logo{
  color:#f7f3ee;
  margin-left: 20px;
  font-size: large;
}
.logo a{
  text-decoration: none;
  color: white;
}
/* nav */

nav ul {
  display: flex;
  width: 400px;
}
nav a {
  text-decoration: none;
  text-transform: capitalize;
  margin-right: 30px;
  color: #000;
}
nav li a:hover{
  text-decoration: none;
}
.acc-btn{
  color:#fff;
}
/* User */
.profile {
  margin-left: 30px;
  cursor: pointer;
}
.profile .image {
  display: flex;
  padding-bottom: 20px;
}
.profile .image img {
  margin-right: 20px;
}
.profile button {
  font-size: 17px;
  font-weight: 500;
}
.profile img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}
.openProfile {
  color: #000;
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
  position: absolute;
  top: 80px;
  right: 10px;
  width: 250px;
  background: F5E6CC;
}
.openProfile .box {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  padding: 5px;
  transition: 0.5s;
}
.openProfile .icon {
  font-size: 20px;
  margin-right: 20px;
}
.openProfile h4 {
  font-size: 15px;
  font-weight: 500;
}
.openProfile button:hover {
  background: rgba(147, 75, 255, 0.075);
}
.search-bar {
  border: 2px solid black;
  width: 350px;
  height: 30px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 100px;
}

.search-bar input {
  width: 100%;
  border: none;
  outline: none;
  padding-left: 15px;
}

/* Styling the placeholder */
.search-bar input::placeholder {
  color: black;
  text-align: center;
}

header.active {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 0 4px 0 rgb(115 115 115 / 20%);
  z-index: 9999;
  background: #fff;
}
@media screen and (max-width: 768px) {
  header .search {
    display: none;
  }
  nav {
    display: none;
  }
}
