.login {
  position: relative;
  margin-bottom: 50px;
}
.login .text {
  position: absolute;
  top: 40%;
  left: 50%;
  z-index: 1;
  text-align: center;
  color: #fff;
}
.login .text h3 {
  font-weight: 600;
  margin-bottom: 10px;
}
.login .text h1 {
  font-size: 40px;
}
.link a{
  text-decoration: none;
  color: #212121; 
}
button:disabled {
  background: #474a7c;
  cursor: not-allowed;
}
.something-w-w{
  color: red;
  font-weight:bold ;
  margin-left: 630px;
}