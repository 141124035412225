.accountInfo {
  margin: 80px 0;
}
.accountInfo .container {
  padding: 50px;
  max-width: 60%;
  margin: auto;
}
.accountInfo .content {
  margin-top: 80px;
  display: flex;
  justify-content: flex-start;
  position: relative;
}
.accountInfo .left {
  width: 100px;
  position: relative;
}
.accountInfo .right {
  width: calc(100% - 18px);
  margin-left: 50px;
}

.accountInfo .left .img input {
  width: 150px;
  height: 150px;
  position: relative;
  z-index: 2;
  opacity: 0;
}
.accountInfo .icon,
.accountInfo .img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  z-index: 1;
  border-radius: 50%;
  object-fit: cover;
}
.accountInfo .right label {
  font-size: 17px;
  color: #000;
  margin-bottom: 10px;
  display: block;
}
.profile h4 {
  text-transform: capitalize;
  color: #000;
  font-weight: 500;
}
@media (max-width: 480px) {
  .accountInfo .container {
    padding: 10px;
    max-width: 95%;
    margin: auto;
  }
}