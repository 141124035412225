.newPost {
  margin-top: 50px;
}
.newPost .container {
  max-width: 50%;
}
.newPost i {
  font-size: 25px;
  margin-right: 20px;
  margin-top: -20px;
}
.newPost img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.newPost .img {
  position: relative;
}
.newPost form {
  width: 100%;
  margin: 0;
  padding: 0;
  box-shadow: none;
  border: none;
}
.newPost .inputfile input {
  width: 100%;
  height: 300px;
  position: relative;
  z-index: 2;
  opacity: 0;
}
.newPost .inputfile label {
  font-size: 30px;
  color: #000;
  margin-left: 0;
  width: 100%;
  display: block;
  cursor: pointer;
}
.newPost .img img {
  /*position: absolute;
  top: 0;
  left: 0;*/
  width: 100%;
  height: 300px;
  z-index: 1;
  border-radius: 5px;
}
@media (max-width: 480px) {
 
  .newPost .container {
    max-width: 80%;
  }
}
